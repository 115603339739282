<template>
  <div>
    <div class="searchNav">
      <el-button
        class="button"
        icon="el-icon-plus"
        type="primary"
        style="margin-left: 0"
        @click="handleAdd"
        >新增</el-button
      >
      <div class="searchParams">
        <div>
          <radioGroup @radioChange="radioChange" @complete="radioComplete" />
        </div>
        <el-input
          class="input"
          v-model="queryParam.condition"
          placeholder="请输入编号/名称"
          clearable
          @keydown.enter.native="getDataList"
        ></el-input>
        <el-button class="button" @click="getDataList">查询</el-button>
      </div>
    </div>
    <div>
      <el-table
        :data="tableData"
        v-loading="loading"
        style="width: 100%"
        :max-height="height"
      >
        <el-table-column prop="SchemeName" label="方案名称" width="200">
        </el-table-column>
        <el-table-column prop="cCusName" label="创建日期" width="200">
          <template slot-scope="scope">
            {{ scope.row.CreateTime.substring(0, 10) }}
          </template>
        </el-table-column>
        <el-table-column prop="Day" label="日期范围" width="200">
          <template slot-scope="scope"> {{ scope.row.Day }}天 </template>
        </el-table-column>
        <el-table-column prop="GroupName" label="用户组" width="200">
          <template slot-scope="scope">
            <el-button type="text" @click="viewGroup(scope.row.UserJson)">{{
              scope.row.GroupName
            }}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="IsDefauilt" label="是否默认" width="200">
          <template slot-scope="scope">
            {{ scope.row.IsDefauilt ? "是" : "否" }}
          </template>
        </el-table-column>
        <el-table-column prop="Remark" label="备注"> </el-table-column>
        <el-table-column label="操作" width="130" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              class="marginRight"
              @click="setDefault(scope.row.Id)"
              :disabled="scope.row.IsDefauilt"
              >设为默认</el-button
            >
            <el-popconfirm
              title="此操作将永久删除当前数据,确认继续吗?"
              @confirm="del(scope.row.Id)"
            >
              <el-button type="text" slot="reference">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        layout="total,prev, pager, next,sizes"
        :total="pagination.total"
        :current-page="pagination.current"
        :page-size="pagination.pageSize"
        :page-sizes="pagination.pageSizeOptions"
        style="text-align: right; margin-top: 8px; padding: 0"
        @current-change="currentChange"
        @size-change="handleSizeChange"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="新增分组"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-form ref="form" :model="form" label-width="80px" :rules="rules">
        <el-form-item label="方案名称" prop="SchemeName">
          <el-input v-model="form.SchemeName"></el-input>
        </el-form-item>
        <el-form-item label="客户分组" prop="GroupId">
          <el-select style="width: 100%" v-model="form.GroupId" filterable>
            <el-option
              v-for="item in groupList"
              :key="item.Id"
              :label="item.GroupName"
              :value="item.Id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="日期区间" prop="Day">
          <span>近</span>
          <el-input-number
            style="margin: 0 10px"
            v-model="form.Day"
            :min="1"
            :max="30"
          ></el-input-number>
          <span>天</span>
        </el-form-item>
        <el-form-item label="备注" prop="Reamrk">
          <el-input type="textarea" v-model="form.Reamrk"></el-input>
        </el-form-item>
        <el-form-item label="是否默认" prop="IsDefauilt">
          <el-switch v-model="form.IsDefauilt"></el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confrimSolution">确 定</el-button>
      </span>
    </el-dialog>
    <solutionForm ref="solutionForm" />
  </div>
</template>

<script>
import solutionForm from "./solutionForm.vue";
import radioGroup from "@/components/CompanyRadioGroup";
export default {
  components: {
    radioGroup,
    solutionForm,
  },
  data() {
    return {
      form: {},
      dialogVisible: false,
      loading: false,
      rules: {
        SchemeName: [
          { required: true, message: "请输入方案名称", trigger: "blur" },
        ],
        GroupId: [{ required: true, message: "请选择分组", trigger: "change" }],
        Day: [{ required: true, message: "请填写日期区间", trigger: "blur" }],
      },
      tableData: [],
      groupList: [],
      queryParam: { SupplieId: "全部" },
      pagination: {
        current: 1,
        pageSize: 25,
        total: 0,
        pageSizeOptions: [25, 50, 100],
      },
      sorter: {
        field: "Id",
        order: "desc",
      },
    };
  },
  computed: {
    height() {
      return document.documentElement.clientHeight * 0.75;
    },
  },
  mounted() {
    this.getDataList();
  },

  methods: {
    viewGroup(json) {
      this.$refs.solutionForm.open(JSON.parse(json).usregroup.id);
    },
    setDefault(id) {
      this.$http
        .post("/BO/BilllistUser/UpdataIsDefauilt?Id=" + id)
        .then((res) => {
          if (res.Success) {
            this.$message.success("操作成功");
            this.getDataList();
          } else this.$message.error(res.Msg);
        });
    },
    handleClose(done) {
      this.form = {};
      done();
    },
    confrimSolution() {
      this.$refs.form.validate((valid) => {
        if (!valid) return;
        this.$http
          .post("/BO/BilllistUser/SaveData", {
            ...this.form,
            CompanyId: this.queryParam.SupplieId,
          })
          .then((res) => {
            if (res.Success) {
              this.getDataList();
              this.form = {};
              this.$message.success("操作成功");
              this.dialogVisible = false;
            } else this.$message.error(res.Msg);
          });
      });
    },
    handleAdd() {
      if (this.queryParam.SupplieId.split(",").length == 1) {
        this.dialogVisible = true;
      } else this.$message.error("请选择公司");
      this.form = {
        Day: 1,
        CompanyId: this.queryParam.SupplieId,
        IsDefauilt: false,
      };
      this.getGroupList();
    },
    del(id) {
      this.$http.post("/BO/BilllistUser/DeleteData", [id]).then((res) => {
        if (res.Success) {
          this.getDataList();
          this.$message.success("操作成功");
        } else this.$message.error(res.Msg);
      });
    },
    radioComplete(e) {
      if (e) {
        this.queryParam.SupplieId = e;
        this.getDataList();
      }
    },
    radioChange(e) {
      if (e == "more") return;
      this.queryParam.SupplieId = e;
      this.getDataList();
    },
    currentChange(page) {
      this.pagination.current = page;
      this.getDataList();
    },
    handleSizeChange(lint) {
      this.pagination.pageSize = lint;
      this.getDataList();
    },
    getDataList() {
      this.loading = false;
      this.$http
        .post("/BO/BilllistUser/GetDataList", {
          PageIndex: this.pagination.current,
          PageRows: this.pagination.pageSize,
          SortField: this.sorter.field || "Id",
          SortType: this.sorter.order,
          Search: this.queryParam,
        })
        .then((resJson) => {
          this.loading = false;
          if (resJson.Success) {
            this.tableData = resJson.Data;
            if (this.tableData.length > 0) {
              const pagination = { ...this.pagination };
              pagination.total = resJson.Total;
              this.pagination = pagination;
            }
          }
        });
    },
    getGroupList() {
      this.$http
        .post("/BO/CustomerGroup/GetDataList", {
          Search: {
            SupplieId: this.queryParam.SupplieId,
          },
        })
        .then((resJson) => {
          this.loading = false;
          if (resJson.Success) {
            this.groupList = resJson.Data;
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
.searchNav .button {
  background: #d50700;
  border-color: #d50700;
  color: #fff;
}
.marginRight {
  margin-right: 10px;
}
</style>